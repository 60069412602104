<template>
    <!-- 素材列表页 -->
    <div class="reportList">
        <el-card>
            <div class="title clearFix">
                <div class="floatLeft">
                    <el-select class="themeInput" v-model="submitStatus" placeholder="请选择状态" @change="getReportList">
                        <el-option
                        v-for="item in submitList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="expSearch">  
                    <el-input
                        clearable
                        placeholder="请输入内容"
                        v-model="input"
                        @clear="getReportList">
                        <el-button slot="append" class="el-icon-search" @click="getReportList" ></el-button>
                    </el-input>
                </div>
             </div>
            <el-table
                :data="reportList"
                style="width: 100%">
                <el-table-column
                    type="index" 
                    :index="indexMethod"
                    width="150"
                    label="序号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="作业名称">
                </el-table-column>
                <el-table-column
                    prop=""
                    label="提交剩余时间">
                </el-table-column>
                <el-table-column
                    label="状态">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.status">未提交</div>
                        <div v-if="scope.row.status == 1">已提交</div>
                        <div v-if="scope.row.status == 2">已评阅</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="score"
                    label="分数">
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <div class="answerText" v-if="!scope.row.status" @click="gotoANswer(scope.row)">作答</div>
                        <div class="lookText" v-if="scope.row.status == 1 || scope.row.status == 2" @click="gotoANswer(scope.row)">查看</div>
                    </template>
                </el-table-column>
            </el-table>
            <div>
                <el-pagination
                    @current-change="getReportList"
                    background
                    layout="prev, pager, next"
                    :current-page.sync="page"
                    :total="dataTotal"
                    v-if="dataTotal != 0"
                >
                </el-pagination>
            </div>
        </el-card>
        <!-- <div class="left-module">

            <div>
                <table>
                    <tr :id="item.project_id" 
                        v-for="(item, index) in reportList"
                        :key="index"
                        class="tr-border">

                        <td class="width3">
                            {{item.index}}：
                        </td>
                        <td class="td-project" @click="getReportDetail(item.project_id,item.name)">
                            <span v-if="item.status == 0" class="status">(未提交)</span>
                            <span v-if="item.status == 1" class="status">(已提交)</span>
                            <span v-if="item.status == 2" class="status">(已评阅)</span>
                            <span>{{item.name}}</span>
                        </td>
                    </tr>                 
                </table>
            </div>
        </div> -->

    </div>
</template>
<style lang="scss" >
    .reportList .el-dialog__body{
        padding: 30px 20px ;
        padding-top: 5px !important;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        padding-bottom: 30px;
    }
    .reportList .el-table .brown {
        background-color: #FAFAFA;
    }
    .reportList .el-input.is-disabled .el-input__inner {
        border-color: #E4E7ED;
        cursor: not-allowed;
        background-color:#9ba7a9;
        color:#FFF;
    }
    .reportList .el-table__body-wrapper{
    height: 70vh !important;
    overflow: scroll!important;
    scrollbar-width: none!important;
    -ms-overflow-style: none!important;
}
.reportList .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
    .reportList .el-table__body-wrapper::-webkit-scrollbar{
        display: none !important
    }
    .reportList .el-table{
    font-size: 0.8333vw !important;
}
.reportList .el-table__row{
    height: 7vh
}
</style>
<style lang="scss" scoped>
    @import "@/assets/css/student/report/reportList.scss";
</style>

<script>
import reportList from "@/assets/js/student/report/reportList.js";
export default {
    ...reportList
};
</script>
